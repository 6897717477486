import { AbstractCRUDsService } from './abstract_cruds.service';
import { Injectable } from '@angular/core';
import { MixpanelService } from './mixpanel.service';
import { HttpClientService } from './http-client.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserPilotService } from './user-pilot.service';
import { AuthenticationTypeE, CurrentUserI } from '../models/user';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, combineLatest, EMPTY, firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { MARKET_CODE_BE, MARKET_CODE_LU, MarketI } from '../models/market';
import { Credentials, LocalStorageAdapter } from '../shared/utils/local-storage.adapter';
import { MarketService } from './market.service';
import { CompanyManagerI } from '../models/companyManager';
import { RoleService } from './role.service';
import { ResetPasswordTokenI } from '../models/resetPasswordToken';
import { Configuration } from '../constant/configuration';
import { ExposedAutologonService } from '../public/services/exposed-autologon.service';
import { userAgentIsGoogleBot } from '../shared/utils/browser';

type IAuthenticationResponse = { [key: string]: string };

@Injectable({ providedIn: 'root' })
export class AuthenticateService extends AbstractCRUDsService {
    serviceUrl = '/webfront';

    constructor(
        public localStorageAdapter: LocalStorageAdapter,
        public http: HttpClient,
        public httpService: HttpClientService,
        private cookieService: CookieService,
        private mixPanelService: MixpanelService,
        private userPilotService: UserPilotService,
        private marketService: MarketService,
        private roleService: RoleService,
        private configuration: Configuration,
        private exposedAutologonService: ExposedAutologonService
    ) {
        super('/authenticate', httpService);
        setInterval(() => {
            if (this.isLogged()) {
                void this.isAlive();
            }
        }, 60000 * 5); // 5 minutes
    }

    /**
     * deprecated: use observable instead
     */
    currentUser: CurrentUserI;
    private currentUserSubject = new BehaviorSubject<CurrentUserI | null>(null);
    currentUser$: Observable<CurrentUserI | null> = this.currentUserSubject.asObservable();
    isAutoLogonUser$ = this.currentUser$.pipe(map(user => user && user.autologon));
    currentUserMarkets$ = combineLatest([this.currentUser$, this.marketService.allMarkets$]).pipe(
        map(([user, markets]) => {
            if (user == null) {
                return [];
            } else {
                return markets.filter(market => {
                    return user.markets.indexOf(market.id) >= 0;
                });
            }
        })
    );
    belongsToBelgiumMarket$ = this.currentUserMarkets$.pipe(
        map(markets => {
            return markets.find(market => market.code === MARKET_CODE_BE) != null;
        })
    );

    belongsToLuxembourgMarket$ = this.currentUserMarkets$.pipe(
        map(markets => {
            return markets.find(market => market.code === MARKET_CODE_LU) != null;
        })
    );

    isLogged(): boolean {
        return this.currentUser != null || this.cookieService.get('JSESSIONID') != null;
    }

    /**
     * Login service to authenticate a user via a credential
     */
    async login(credential: Credentials, authenticationType: AuthenticationTypeE): Promise<IAuthenticationResponse> {
        return new Promise((resolve, reject) => {
            credential.timestamp = Date.now();
            this.localStorageAdapter.credentials = credential;
            const data: string =
                'j_username=' +
                encodeURIComponent(credential.email) +
                '&j_password=' +
                encodeURIComponent(credential.password) +
                '&submit=Login';
            const configuration = {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                ignoreAuthModule: 'ignoreAuthModule'
            };

            return this.http
                .post<IAuthenticationResponse>(`${this.serviceUrl}/authentication`, data, configuration)
                .pipe(
                    switchMap(response => {
                        return this.afterSuccessLogin(authenticationType).pipe(map(() => response));
                    }),
                    catchError(error => {
                        void this.removeLocalCredentials();
                        // throw err;
                        reject(error);
                        return EMPTY;
                    })
                )
                .subscribe(authResponse => {
                    this.mixPanelService.track(this.mixPanelService.constants.actions.registration.login, {});
                    resolve(authResponse);
                });
        });
    }

    afterSuccessLogin(authenticationType: AuthenticationTypeE): Observable<[CurrentUserI, MarketI[]]> {
        return combineLatest([this.fetchCurrentUser(), this.marketService.getMarkets()]).pipe(
            tap(([currentUser]) => {
                this.currentUser.authenticationType = authenticationType;
                this.localStorageAdapter.userAuthenticationType = authenticationType;
                this.mixPanelService.identify(currentUser);
                this.userPilotService.identify(currentUser);
            })
        );
    }

    /**
     * Get the previous credentials
     */
    getPreviousCredentials(): Credentials | null {
        return this.localStorageAdapter.credentials;
    }

    getPreviousAuthenticationType(): AuthenticationTypeE | null {
        return this.localStorageAdapter.userAuthenticationType;
    }

    private removeLocalCredentials(): void {
        this.localStorageAdapter.credentials = null;
        this.currentUser = undefined;
        this.currentUserSubject.next(null);
        // we needed to remove session roles, after logout
        // to force actualize on next login
        this.roleService.resetSessionRoles();
        this.userPilotService.closeSession();
    }

    async logout(): Promise<any> {
        this.localStorageAdapter.userAuthenticationType = null;
        this.removeLocalCredentials();
        return this.http.get(`${this.serviceUrl}/logout`).toPromise();
    }

    fetchCurrentUser(): Observable<CurrentUserI> {
        if (userAgentIsGoogleBot()) {
            return of(null);
        }

        return this.http.get<CurrentUserI>(`${this.serviceUrl}/user/current`).pipe(
            tap(currentUser => {
                this.currentUser = currentUser;
                this.currentUser.authenticationType = this.getPreviousAuthenticationType();
                this.currentUserSubject.next(currentUser);
            })
        );
    }

    async getCurrentUser(): Promise<CurrentUserI> {
        return new Promise(resolve => {
            if (this.currentUser) {
                resolve(this.currentUser);
            } else {
                this.fetchCurrentUser().subscribe({
                    next: (currentUser: CurrentUserI) => {
                        resolve(currentUser);
                    },
                    error: () => {
                        void this.fetchAutoLogonUser().then(currentUser => {
                            resolve(currentUser);
                        });
                    }
                });
            }
        });
    }

    async fetchAutoLogonUser(): Promise<CurrentUserI> {
        if (userAgentIsGoogleBot()) {
            return Promise.resolve<null>(null);
        }
        return new Promise(resolve => {
            this.exposedAutologonService.fetchAutologonCredentials().subscribe({
                next: result => {
                    const credential = {
                        email: result.companyUuid,
                        password: result.hash
                    };

                    this.login(credential, AuthenticationTypeE.AUTO_LOGIN_IP)
                        .then(() => {
                            resolve(this.currentUser);
                        })
                        .catch(() => {
                            resolve(null);
                        });
                },
                error: () => resolve(null)
            });
        });
    }

    refreshCurrentUser(): void {
        this.http.get(`${this.serviceUrl}/user/current`).subscribe((result: CurrentUserI) => {
            this.currentUser = result;
            this.currentUser.authenticationType = this.getPreviousAuthenticationType();
            this.currentUserSubject.next(result);
        });
    }

    /**
     * Accept the terms
     */
    async acceptTerms(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.http.post(`${this.serviceUrl}/user/accept-terms`, {}).subscribe(
                () => {
                    this.currentUser = null;
                    this.currentUserSubject.next(null);
                    resolve();
                },
                () => {
                    reject();
                }
            );
        });
    }

    async checkTokenValidity(token: string): Promise<any> {
        return firstValueFrom(this.http.post('/u/check/' + token, {}));
    }

    /**
     * Send an email to the user who forgets his password
     */
    forget(payload): Observable<void> {
        return this.http.post<void>('/u/forget', payload);
    }

    /**
     * Reset the user password
     */
    reset(passwordToken: ResetPasswordTokenI): Observable<void> {
        return this.http.post<void>('/u/reset', passwordToken);
    }

    createPassword(passwordToken: ResetPasswordTokenI): Observable<void> {
        return this.http.post<void>('/u/create-password', passwordToken);
    }

    /**
     * Check if the current user is a temporary user.
     */
    isTemporaryUser(): boolean {
        if (this.currentUser !== undefined) {
            return this.currentUser.roles.includes('TEMPORARY_USER');
        } else {
            return false;
        }
    }

    /**
     * Check if the current user is an auto-logon user.
     */
    isAutoLogonUser(): boolean {
        return this.currentUser != null && this.currentUser.autologon;
    }

    /**
     * Send an heartbeat to the server to keep alive the session.
     */
    async isAlive(): Promise<any> {
        return firstValueFrom(
            this.http
                .get(`${this.serviceUrl}/user/alive`, {
                    headers: new HttpHeaders({ skipSpinner: 'true' })
                })
                .pipe(
                    catchError(err => {
                        this.currentUser = null;
                        this.currentUserSubject.next(null);
                        throw err;
                    })
                )
        );
    }

    /**
     * Creates a user in the company associated to the given token.
     */
    async createUserFromToken(token: string, payload: CompanyManagerI): Promise<any> {
        return firstValueFrom(this.http.post(` /u/user/create/${token}`, payload));
    }

    isAllowedIp(): boolean {
        const allowedIps = this.currentUser?.props?.sso_ip_check_2_print_download;
        const userIP = this.currentUser?.myIp;
        if (!allowedIps || !userIP) {
            // if there is no sso_ip_check_2_print_download property in config, by default we allow access without checking IP
            return true;
        }

        let isValidIp = false;
        allowedIps.split(',').forEach(ip => {
            if (ip.trim() === userIP) {
                isValidIp = true;
            }
        });

        return isValidIp;
    }
}

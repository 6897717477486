import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    NgbCalendar,
    NgbDate,
    NgbDateParserFormatter,
    NgbDatepickerI18n,
    NgbDateStruct,
    NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { Configuration } from '../../../constant/configuration';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

const I18N_VALUES = {
    fr: {
        weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
        monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
        months: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre'
        ],
        weekLabel: 'sem'
    },
    en: {
        weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ],
        weekLabel: 'week'
    },
    nl: {
        weekdays: ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'],
        monthsShort: ['Jan', 'Feb', 'Mrt', 'Apr', 'Mei', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        months: [
            'Januari',
            'Februari',
            'Maart',
            'April',
            'Mei',
            'Juni',
            'Juli',
            'Augustus',
            'September',
            'Oktober',
            'November',
            'December'
        ],
        weekLabel: 'week'
    }
};

@Component({
    selector: 'app-datepicker-range',
    templateUrl: './app.datepicker.range.html',
    styleUrls: ['./app.datepicker.range.scss'],
    providers: [{ provide: NgbDatepickerI18n, useClass: AppDatepickerRangeComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class AppDatepickerRangeComponent extends NgbDatepickerI18n {
    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate | null;
    toDate: NgbDate | null;
    dateRange = true;
    todayActivator = false;

    @Input() inline: boolean;
    @Input() set dateFrom(value: string) {
        if (value) {
            const date = moment(value, this.configuration.dateFormats.ISO);
            // @ts-ignore
            this.fromDate = { day: date.date(), month: date.month() + 1, year: date.year() };
        } else {
            this.fromDate = null;
        }
    }
    @Input() set dateTo(value: string) {
        if (value) {
            const date = moment(value, this.configuration.dateFormats.ISO);
            // @ts-ignore
            this.toDate = { day: date.date(), month: date.month() + 1, year: date.year() };
        } else {
            this.toDate = null;
        }
    }
    @Output() dateFromChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() dateToChange: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('datepicker') datepicker: NgbInputDatepicker;

    constructor(
        public formatter: NgbDateParserFormatter,
        private calendar: NgbCalendar,
        private configuration: Configuration,
        private translationService: TranslateService
    ) {
        super();
    }

    get minDate(): any {
        return { year: 1830, month: 1, day: 1 };
    }

    get maxDate(): any {
        return { year: new Date().getUTCFullYear() + 5, month: 12, day: 31 };
    }

    format(date: NgbDate): string {
        return this.formatter.format(date);
    }

    onExactDateSelection(date: NgbDate): void {
        this.fromDate = date;
        this.toDate = date;
        this.dateFromChange.emit(this.formatter.format(this.fromDate));
        this.dateToChange.emit(this.formatter.format(this.toDate));
    }

    onDateSelection(date: NgbDate): void {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && !date.before(this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
        this.dateFromChange.emit(this.formatter.format(this.fromDate));
        this.dateToChange.emit(this.formatter.format(this.toDate));
    }

    isHovered(date: NgbDate): boolean {
        return (
            this.fromDate &&
            !this.toDate &&
            this.hoveredDate &&
            date.after(this.fromDate) &&
            date.before(this.hoveredDate)
        );
    }

    isInside(date: NgbDate): boolean {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate): boolean {
        return (
            date.equals(this.fromDate) ||
            (this.toDate && date.equals(this.toDate)) ||
            this.isInside(date) ||
            this.isHovered(date)
        );
    }

    onToDateChange(value: string): void {
        this.toDate = this.validateInput(this.toDate, value);
        const formattedDate = this.formatter.format(this.toDate);
        this.dateToChange.emit(formattedDate);
    }

    onFromDateChange(value: string): void {
        this.fromDate = this.validateInput(this.fromDate, value);
        this.dateFromChange.emit(this.formatter.format(this.fromDate));
    }

    onExactDateChange(value: string): void {
        this.toDate = this.validateInput(this.toDate, value);
        this.fromDate = this.validateInput(this.fromDate, value);
        const formattedDate = this.formatter.format(this.toDate);
        this.dateToChange.emit(formattedDate);
        this.dateFromChange.emit(formattedDate);
    }

    handleTodayActivatorChanged(): void {
        if (this.todayActivator) {
            const currentDate = new Date();
            this.dateRange = false;

            this.fromDate = this.toDate = null;
            this.fromDate = new NgbDate(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate());
            this.toDate = this.fromDate;
            const formattedDate = this.formatter.format(this.fromDate);
            this.dateToChange.emit(formattedDate);
            this.dateFromChange.emit(formattedDate);
        }
    }

    handleDateRangeChanged(): void {
        if (!this.dateRange) {
            this.toDate = this.fromDate;
            const formattedDate = this.formatter.format(this.toDate);
            this.dateToChange.emit(formattedDate);
        }
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = moment(input, 'DD-MM-YYYY');
        const dateStruct = { year: parsed.year(), month: parsed.month() + 1, day: parsed.date() };
        const ngbDate = NgbDate.from(dateStruct);
        return parsed != null && this.calendar.isValid(ngbDate) ? ngbDate : currentValue;
    }

    get currentLang(): string {
        return this.translationService.currentLang?.toLowerCase() || 'fr';
    }

    getWeekdayLabel(weekday: number): string {
        return I18N_VALUES[this.currentLang]?.weekdays[weekday - 1];
    }
    getWeekLabel(): string {
        return I18N_VALUES[this.currentLang]?.weekLabel;
    }
    getMonthShortName(month: number): string {
        return I18N_VALUES[this.currentLang]?.monthsShort[month - 1];
    }
    getMonthFullName(month: number): string {
        return I18N_VALUES[this.currentLang]?.months[month - 1];
    }
    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }

    getWeekdayShortName(weekday: number): string {
        return this.getWeekdayLabel(weekday);
    }

    toggleDatePicker(): void {
        if (this.fromDate) {
            this.datepicker.startDate = this.fromDate;
            this.datepicker.navigateTo(this.fromDate);
        }
    }
}

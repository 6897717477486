import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import { FiltersInitI } from '../models/filter';
import { selectEntity, upsertEntities, withEntities } from '@ngneat/elf-entities';
import { createRequestsCacheOperator, updateRequestCache, withRequestsCache } from '@ngneat/elf-requests';
import { Observable } from 'rxjs/internal/Observable';

interface InitFiltersState {
    userUuid: string;
    initFilters: FiltersInitI;
}

const store = createStore(
    { name: 'initFilters' },
    withEntities<InitFiltersState, 'userUuid'>({ idKey: 'userUuid' }),
    withRequestsCache<`init-filters-${string}`>()
);

export const skipWhileInitFiltersCached = createRequestsCacheOperator(store);
export const CACHED_INIT_FILTERS_KEY = 'init-filters';

@Injectable({ providedIn: 'root' })
export class InitFiltersRepository {
    selectInitFilters$(userUuid: string): Observable<FiltersInitI> {
        return store.pipe(selectEntity(userUuid, { pluck: 'initFilters' }));
    }

    public upsertInitFilters(userUuid: string, initFilters: FiltersInitI): void {
        store.update(
            upsertEntities({ userUuid, initFilters }),
            updateRequestCache(`${CACHED_INIT_FILTERS_KEY}-${userUuid}`)
        );
    }
}

import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import 'whatwg-fetch';
import { TranslationLoadedService } from './translation-loaded.service';
import * as _ from 'lodash';

export class MultiTranslateHttpLoader implements TranslateLoader {
    constructor(private translationLoadedService: TranslationLoadedService) {}
    public getTranslation(lang: string): Observable<any> {
        const resources: string[] = [
            '_global.json',
            'account.json',
            'advancedsearch.json',
            'authentication.json',
            'book.json',
            'bookcode.json',
            'company.json',
            'companybook.json',
            'companymanager.json',
            'configurations.json',
            'contact.json',
            'document.json',
            'folder.json',
            'home.json',
            'license.json',
            'licensecompany.json',
            'magazine.json',
            'magazinesubscription.json',
            'myalert.json',
            'mybooks.json',
            'periodic.json',
            'search.json',
            'statistic.json',
            'subscription.json',
            'termsAndAgreements.json',
            'users.json',
            'workspace.json',
            'timesheet.json',
            'translation.json',
            'streamAndBucket.json',
            'lawbranch.json',
            'library.json',
            'mycompany.json',
            'publicLibrary.json',
            'accounting.json',
            'feature.json'
        ];

        const requests = resources.map(resource => {
            const path = 'i18n/' + lang.toLowerCase() + '/' + resource;
            // when using HttpClient with the new HttpClientInterceptor
            // return the error => MultiTranslateHTTPLoader.ts:51 Trying to get the AngularJS injector before it being set.
            // we may be able to switch back to HttpClient at the end of the migration

            return from(fetch(path).then(async response => response.json())).pipe(
                catchError(res => {
                    console.error('Something went wrong for the following translation file:', path);
                    console.error(res.message);
                    return of({});
                })
            );
        });
        return forkJoin(requests).pipe(
            map(response => {
                this.translationLoadedService.loaded$().next();
                return _.merge({}, ...response);
            })
        );
    }
}

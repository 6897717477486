import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Configuration {
    rssFeed = 'https://www.legitech.lu/?feed=progilex';

    defaultTabTypes = ['ALL', '101', '103', '104', '102', '107', '105'];

    lawBranchFilterMarkets = [1];
    /**
     * Keys for filters, when the markets is undefined it means that should be show for every market
     */
    keysByTab = [
        {
            tab: 'ALL',
            filters: [{ key: 'workspace', markets: [0] }, { key: 'language' }, { key: 'territory' }]
        },
        {
            tab: '105', // Doctrine
            filters: [{ key: 'magazine' }, { key: 'book' }, { key: 'publisher' }, { key: 'author' }]
        },
        {
            tab: '101', // Legislation
            filters: [{ key: 'nature' }]
        },
        {
            tab: '102', // Jurisprudence
            filters: [{ key: 'jurisdiction' }]
        },
        {
            tab: '107',
            filters: [{ key: 'guidelineSource' }]
        }
    ];
    translatableFilters = ['workspace', 'language', 'territory', 'nature', 'jurisdiction', 'guidelineSource'];

    translationContext = {
        ALL: 'ALL',
        DOCUMENT_TYPE: 'DOCUMENT_TYPE',
        NATURE: 'NATURE',
        WORKSPACE: 'WORKSPACE',
        JURISDICTION: 'JURISDICTION',
        GUIDELINE: 'GUIDELINE',
        DOCTRINE_TYPE: 'DOCTRINE_TYPE',
        TERRITORY: 'TERRITORY',
        LANGUAGE: 'LANGUAGE',
        SUBJECT: 'SUBJECT',
        DOCUMENT_SOURCE: 'DOCUMENT_SOURCE',
        SOURCE_TYPE: 'SOURCE_TYPE',
        NONE: 'NONE',
        SEARCH_MODE: 'SEARCH_MODE',
        TOGGLE_CONTEXT: 'TOGGLE_CONTEXT',
        FILTER_TYPE_CONTEXT: 'FILTER_TYPE_CONTEXT',
        COMMERCIAL_BANNER: 'COMMERCIAL_BANNER',
        LAW_BRANCH: 'LAW_BRANCH',
        ROLE: 'ROLE',
        KEY_META_DATA: 'KEY_META_DATA',
        SECTOR: 'SECTOR',
        FREE_USER_TYPE: 'FREE_USER_TYPE',
        LOGIN_DIALOG_TO_ACCESS_BOOK: 'LOGIN_DIALOG_TO_ACCESS_BOOK'
    };

    timesheetStatus = {
        DELETED: 'DELETED',
        IN_PROGRESS: 'IN_PROGRESS',
        SAVED: 'SAVED'
    };

    mixpanel = {
        prodToken: 'a253b3f092152e28cb1012be043681af',
        prodDomain: 'app.lexnow.io',
        uatToken: '4eb8046b1ec7c4a571ce4b66a1ce3a06',
        uatDomain: 'app.lexnow-labs.com',

        actions: {
            registration: {
                freeTrial: 'registration.free',
                subscribePaid: 'registration.paid',
                requestTransition: 'registration.transition',
                login: 'registration.login'
            },
            administration: {
                account: {
                    startTimesheet: 'administration.account.startTimesheet',
                    saveTimesheet: 'administration.account.saveTimesheet'
                },
                users: {
                    inviteColleague: 'administration.users.inviteColleague'
                },
                organization: {
                    changeSubscriptionPlan: 'administration.organization.changeSubscriptionPlan',
                    cancelSubscriptionPlan: 'administration.organization.cancelSubscriptionPlan',
                    exportConsumptionHistory: 'administration.organization.exportConsumptionHistory',
                    updateLimitPreferences: 'administration.organization.updateLimitPreferences',
                    updateAutomaticRefillPreferences: 'administration.organization.updateAutomaticRefillPreferences'
                }
            },
            functionalities: {
                search: {
                    launch: 'functionalities.search.launch',
                    traditionalFilters: 'functionalities.search.traditionalFilters',
                    visualDynamicFilters: 'functionalities.search.visualDynamicFilters',
                    advancedSearch: 'functionalities.search.advancedSearch',
                    save: 'functionalities.search.save'
                },
                stream: {
                    subscribe: 'functionalities.stream.subscribe',
                    unsubscribe: 'functionalities.stream.unsubscribe',
                    consult: 'functionalities.stream.consult'
                }
            },
            contentConsumption: {
                document: {
                    open: 'contentConsumption.document.open',
                    view: 'contentConsumption.document.view',
                    save: 'contentConsumption.document.save',
                    download: 'contentConsumption.document.download',
                    print: 'contentConsumption.document.print',
                    setUpAlert: 'contentConsumption.document.setUpAlert'
                },
                consult: {
                    datasheet: 'contentConsumption.consult.datasheet',
                    linkedDocuments: 'contentConsumption.consult.linkedDocuments',
                    automaticTranslation: 'contentConsumption.consult.automaticTranslation',
                    modifiers: 'contentConsumption.consult.modifiers',
                    changes: 'contentConsumption.consult.changes'
                },
                accessLinkedDocument: 'contentConsumption.accessLinkedDocument',
                accessModifierDocument: 'contentConsumption.accessModifierDocument',
                executeAutomaticTranslation: 'contentConsumption.consult.executeAutomaticTranslation',
                highlightContent: 'contentConsumption.consult.highlightContent',
                extractReference: 'contentConsumption.consult.extractReference'
            },
            support: {
                consult: 'support.consult',
                ticket: {
                    create: 'support.ticket.create',
                    close: 'support.close.ticket'
                },
                onBoarding: {
                    launch: 'support.onBoarding.launch',
                    complete: 'support.onBoarding.complete'
                },
                accessHelpCenter: 'support.accessHelpCenter',
                consultHelpPage: 'support.consultHelpPage'
            },
            access: {
                latestDocuments: 'access.latestDocuments',
                suggestions: 'access.suggestions',
                myDocuments: 'access.myDocuments',
                myLibrary: 'access.myLibrary',
                myFreeLibrary: 'access.myFreeLibrary',
                myAlerts: 'access.myAlerts',
                myParameters: 'access.myParameters',
                advancedSearch: 'access.advancedSearch',
                mySearches: 'access.mySearches',
                linkedDocuments: 'access.linkedDocuments',
                linkedArticles: 'access.linkedArticles',
                streamsAndBuckets: 'access.streamsAndBuckets',
                streamsAndBucketsFactory: 'access.streamsAndBucketsFactory',
                streamsAndBucketsMarketPlace: 'access.streamsAndBucketsMarketPlace',
                streamsAndBucketsMyFlows: 'access.streamsAndBucketsMyFlows'
            }
        }
    };

    userPilot = {
        token: '6ms27o3'
    };

    languages = {
        fr: { twoLettersLanguage: 'fr', cssClass: 'flag-fr', languageKey: 'global.language.FR', alt: 'Français' },
        en: { twoLettersLanguage: 'en', cssClass: 'flag-gb', languageKey: 'global.language.EN', alt: 'English' },
        nl: { twoLettersLanguage: 'nl', cssClass: 'flag-nl', languageKey: 'global.language.NL', alt: 'Nederlands' }
    };

    languagesArray = ['EN', 'FR', 'NL'];

    dateFormats = {
        datepicker: {
            en: 'mm/dd/yyyy',
            fr: 'dd/mm/yyyy',
            nl: 'dd/mm/yyyy'
        },
        en: 'MM/DD/YYYY',
        fr: 'DD/MM/YYYY',
        de: 'DD/MM/YYYY',
        ng: {
            en: 'MM/dd/yyyy',
            fr: 'dd/MM/yyyy',
            de: 'dd/MM/yyyy'
        },
        ISO: 'YYYY-MM-DD'
    };

    datetimeFormats = {
        en: 'MM/DD/YYYY HH:mm:ss',
        fr: 'DD/MM/YYYY HH:mm:ss',
        de: 'DD/MM/YYYY HH:mm:ss'
    };

    timeFormat = {
        ng: 'HH:mm:ss'
    };

    sortTypes = [
        {
            value: 'RELEVANCE',
            translation: 'document.sort.relevance'
        },
        {
            value: 'DOCUMENT_DATE',
            translation: 'document.sort.date'
        },
        {
            value: 'APPLICABILITY_DATE',
            translation: 'document.sort.applicability'
        }
    ];

    messageBusChannels = {
        LANGUAGE_CHANNEL: 'language_channel',
        HEADERMENU_CHANNEL: 'headermenu_channel',
        HEADERMENU_GLOBAL_CHANNEL: 'headermenu_global_channel',
        ALERT_CHANNEL: 'alert_channel',
        SEARCH_BAR_CHANNEL: 'search_bar_channel',
        NAV_BAR_CHANNEL: 'nav_bar_channel',
        VALID_VIEW_CHANNEL: 'doc_statistics_channel',
        TIMESHEET_CHANNEL: 'timesheet_channel',
        TRANSLATION_ADMIN_CHANNEL: 'translation-admin-channel',
        DOCUMENT_SEARCH_CHANNEL: 'document-search-channel',
        DOCUMENT_TAB_HITS: 'document-tabs-hits-channel',
        SESSION_CHANNEL: 'session_channel',
        USER_LOGIN_CHANNEL: 'user_login_channel',
        USER_LOGOUT_CHANNEL: 'user_logout_channel',
        LOGIN: 'user-login',
        NOTIFICATION_CHANNEL: 'notification_channel',
        RESTRICTED_ACCESS_CHANNEL: 'restricted_access_channel'
    };

    VALID_VIEW_THRESHOLD = 5000; // 5 seconds

    actionStatisticsTypes = {
        DOC_TRANSLATION: 'DOC_TRANSLATION',
        DOC_DOWNLOAD_PDF: 'DOC_DOWNLOAD_PDF',
        DOC_PRINT: 'DOC_PRINT',
        COMPARE_DOC_LEG: 'COMPARE_DOC_LEG',
        COMPARE_DOWNLOAD_PDF: 'COMPARE_DOWNLOAD_PDF',
        VIEW_HELP: 'VIEW_HELP',
        VIEW_NEWS: 'VIEW_NEWS'
    };

    events = {
        updateUserLanguageEvent: 'event:update_user_language',
        userLoginEvent: 'event:user_login',
        userLogoutEvent: 'event:user_logout',
        headermenuDocumentFilterEvent: 'event:headermenu-document-filters',
        headermenuDocumentSearchEvent: 'event:headermenu-document-search',
        headermenuDocumentUpdateEvent: 'event:headermenu-document-update',
        searchBarDirectiveEvent: 'event:search-bar-event',
        removeCriteriaEvent: 'event:remove-criteria',
        destroy: '$destroy',
        stateChangeStart: '$stateChangeStart',
        scroll: 'scroll',
        remove: 'remove',
        changeBookVersionEvent: 'event:change-book-version',
        changeBookPartEvent: 'event:change-bookpart'
    };

    seniorityAllowancesIndexes = [
        {
            yearPeriod: {
                from: 0,
                to: 4
            },
            monthAmount: 0
        },
        {
            yearPeriod: {
                from: 5,
                to: 9
            },
            monthAmount: 1
        },
        {
            yearPeriod: {
                from: 10,
                to: 14
            },
            monthAmount: 2
        },
        {
            yearPeriod: {
                from: 15,
                to: 19
            },
            monthAmount: 3
        },
        {
            yearPeriod: {
                from: 20,
                to: 24
            },
            monthAmount: 6
        },
        {
            yearPeriod: {
                from: 25,
                to: 29
            },
            monthAmount: 9
        },
        {
            yearPeriod: {
                from: 30
            },
            monthAmount: 12
        }
    ];

    rangeSalaries = [
        {
            from: 0,
            to: 2999.99
        },
        {
            from: 3000,
            to: 6999.99
        },
        {
            from: 7000
        }
    ];

    approximatedMonth = 6;
    approximatedYearOld = 5;
    numberMonthsInYear = 12;

    maxTimeoutReconnectWithoutLogin = 2 * 60 * 60 * 1000;

    localStorageKeys = {
        userSearchKey: 'userSearch',
        userLanguageKey: 'userLanguage',
        consultedDocumentIds: 'consultedDocumentIds',
        credentials: 'credentials',
        publicLibraryDisplayType: 'publicLibraryDisplayType',
        authenticationType: 'authenticationType'
    };

    searchParams = {
        minCharsForSuggest: 3
    };

    links = [
        { link: 'Legilux', url: 'http://www.legilux.public.lu/', code: 'legilux' },
        { link: 'Guichet.lu', url: 'http://www.guichet.public.lu/home/fr/index.html', code: 'guichet' },
        { link: 'Chambre des Députés', url: 'http://www.chd.lu/', code: 'chambre' },
        { link: 'Eur-lex', url: 'global.topics.utile.eurlex.url', code: 'eurlex' },
        { link: 'Commission de surveillance du secteur financier (CSSF)', url: 'http://www.cssf.lu/', code: 'cssf' },
        { link: 'Cour de Justice de l’Union européenne', url: 'https://curia.europa.eu', code: 'curia' },
        { link: 'Cour européenne des droits de l’homme', url: 'http://www.echr.coe.int/', code: 'echr' },
        { link: 'Barreau de Luxembourg et de Diekirch', url: 'https://www.barreau.lu', code: 'barreau' },
        { link: 'Ministère de la Justice', url: 'http://www.mj.public.lu', code: 'mj' },
        {
            link: 'Administration de l’enregistrement et des domaines (AED)',
            url: 'http://www.aed.public.lu',
            code: 'aed'
        },
        {
            link: 'Administration des contributions directes (ACD)',
            url: 'http://www.impotsdirects.public.lu/',
            code: 'acd'
        },
        { link: 'Administration des douanes et accises (ADA)', url: 'http://www.do.etat.lu/', code: 'ada' },
        { link: 'Registre de Commerce et des Sociétés (RCS) luxembourgeois', url: 'http://www.rcsl.lu', code: 'rcsl' },
        { link: 'Association des Banques et Banquiers, Luxembourg (ABBL)', url: 'http://www.abbl.lu/', code: 'abbl' },
        {
            link: 'Association of the Luxembourg Fund Industry (ALFI)',
            url: 'http://www.alfi.lu/about-alfi',
            code: 'alfi'
        },
        { link: 'Commissariat aux assurances', url: 'http://www.commassu.lu/', code: 'caa' },
        { link: 'Commission nationale pour la protection des données (CNPD)', url: 'http://www.cnpd.lu', code: 'cnpd' },
        { link: 'Institut de la propriété intellectuelle Luxembourg (IPIL)', url: 'http://www.ipil.lu', code: 'ipil' },
        { link: 'Centre commun de la sécurité sociale (CCSS)', url: 'http://www.ccss.lu/', code: 'ccss' },
        { link: 'Union Luxembourgeoise des Consommateurs (ULC)', url: 'https://www.ulc.lu/fr/', code: 'ulc' }
    ];

    documentTypeList = ['DOC', 'PAR', 'LEG', 'CIR', 'JUR', 'GUI'];

    documentLinkType = {
        CONTENT_HYPERLINK: 'CONTENT_HYPERLINK',
        DOCUMENT_HYPERLINK: 'DOCUMENT_HYPERLINK',
        EXTERNAL_HYPERLINK: 'EXTERNAL_HYPERLINK'
    };

    documentType = {
        JURISDICTION: 'JUR',
        DOCTRINE: 'DOC',
        PARLIAMENTARY_FOLDER: 'PAR',
        LEGISLATION: 'LEG',
        ADMINISTRATIVE_CIRCULAR: 'CIR',
        GUIDELINE: 'GUI',
        OTHERS_SOURCES: 'othersSources'
    };

    documentTypeLabel = {
        JUR: 'Jurisdiction',
        DOC: 'Doctrine',
        PAR: 'Parliamentary Folder',
        LEG: 'Legislation',
        CIR: 'Administrative Circular',
        GUI: 'Guideline',
        othersSources: 'Other Sources'
    };

    facetLabel = {
        GUIDELINE_SOURCE: 'guidelineSource',
        LANGUAGE: 'language',
        JURISDICTION: 'jurisdiction',
        NATURE: 'nature',
        SOURCE_TYPE: 'sourceType',
        SOURCE: 'source',
        YEAR: 'year',
        BOOK_TYPE: 'bookType',
        TYPE: 'type',
        BOOK: 'book',
        LAW_BRANCH: 'lawBranch',
        LAW_SUB_BRANCH: 'lawSubBranch'
    };

    publicationType = {
        BOOK: 'BOOK'
    };

    documentLinkRelation = {
        DOC_REX: 'DOC_REX',
        DOC_CIT: 'DOC_CIT',
        ART_REX: 'ART_REX',
        ART_CIT: 'ART_CIT'
    };

    bookTypeCode = {
        MONOGRAPHY: 'MONOGRAPHY',
        PERIODIC: 'PERIODIC',
        OUVRAGE: 'OUV',
        MAGAZINE: 'REV',
        PUBLICATION: 'PUB'
    };

    sourceType = {
        MAGAZINE: '1',
        BOOK: '2',
        ARTICLE: '3'
    };

    sourceTypeCodePrefix = {
        BOOK: 'B_',
        MAGAZINE: 'MC_'
    };

    filterAttribute = {
        LEXNOW: 'LEXNOW',
        COUNTRY: 'country',
        END_DATE_OF_APPLICABILITY: 'endDateOfApplicability',
        GUIDELINE_SOURCE: 'guidelineSource',
        LANGUAGE: 'language',
        JURISDICTION: 'jurisdiction',
        NATURE: 'nature',
        BOOK_TYPE: 'bookType',
        SOURCE_TYPE: 'sourceType',
        SOURCE: 'source',
        PARTNER: 'partner',
        TERRITORY: 'territory'
    };

    alert = {
        duration: 2500
    };

    password = {
        patternTenCharLong: /(?=.{10,})/
    };

    documents = {
        terms: {
            lu: {
                fr: {
                    file: 'pdf/cgv_fr_20180524.pdf'
                },
                en: {
                    file: 'pdf/cgv_fr_20180524.pdf'
                },
                nl: {
                    file: 'pdf/cgv_fr_20180524.pdf'
                }
            },
            be: {
                fr: {
                    file: 'pdf/TermsOfUse_FR.pdf'
                },
                en: {
                    file: 'pdf/TermsOfUse_EN.pdf'
                },
                nl: {
                    file: 'pdf/TermsOfUse_NL.pdf'
                }
            }
        },
        gdpr: {
            lu: {
                fr: {
                    file: 'pdf/gdpr_fr_20180822.pdf'
                },
                en: {
                    file: 'pdf/gdpr_fr_20180822.pdf'
                },
                nl: {
                    file: 'pdf/gdpr_fr_20180822.pdf'
                }
            },
            be: {
                fr: {
                    file: 'pdf/PrivacyPolicy_FR.pdf'
                },
                en: {
                    file: 'pdf/PrivacyPolicy_EN.pdf'
                },
                nl: {
                    file: 'pdf/PrivacyPolicy_NL.pdf'
                }
            },
            date: '22/08/2018'
        },
        cookies: {
            lu: {
                fr: {
                    file: 'pdf/cookies_fr_20180524.pdf'
                },
                en: {
                    file: 'pdf/cookies_fr_20180524.pdf'
                },
                nl: {
                    file: 'pdf/cookies_fr_20180524.pdf'
                }
            },
            be: {
                fr: {
                    file: 'pdf/CookiePolicy_FR.pdf'
                },
                en: {
                    file: 'pdf/CookiePolicy_EN.pdf'
                },
                nl: {
                    file: 'pdf/CookiePolicy_NL.pdf'
                }
            },
            date: '24/05/2018',
            pagination: 'pagination_object',
            disposition: 'disposition',
            filter: 'search_filter_object'
        },
        cgvAndGdpr: {
            lu: {
                fr: {
                    file: 'pdf/cgv_gdpr-24052018.pdf'
                },
                en: {
                    file: 'pdf/cgv_gdpr-24052018.pdf'
                },
                nl: {
                    file: 'pdf/cgv_gdpr-24052018.pdf'
                }
            },
            be: {
                fr: {
                    file: 'pdf/GeneralTerms_FR.pdf'
                },
                en: {
                    file: 'pdf/GeneralTerms_EN.pdf'
                },
                nl: {
                    file: 'pdf/GeneralTerms_NL.pdf'
                }
            },
            date: '24/05/2018'
        }
    };

    translator = {
        NO_TRANSLATION: 'NO_TRANSLATION',
        TRANSLATION_IN_PROGRESS: 'TRANSLATION_IN_PROGRESS',
        TRANSLATION_FINISHED: 'TRANSLATION_FINISHED'
    };

    roles = {
        ADMINISTRATOR: 'ADMINISTRATOR',
        COMPANY_MANAGER: 'COMPANY_MANAGER',
        STANDARD_USER: 'USER'
    };

    searchMode = {
        ONE_KEYWORD: 'AT_LEAST_ONE_KEYWORD',
        ALL_KEYWORDS: 'ALL_KEYWORDS',
        EXACT_KEYWORDS: 'EXACTLY_THIS_EXPRESSION'
    };

    numeralAdverbs = ['bis', 'ter', 'quater', 'quinquies', 'sexies', 'septies', 'octies'];

    configuration = {
        alert,
        rssFeed: this.rssFeed,
        languages: this.languages,
        dateFormats: this.dateFormats,
        datetimeFormats: this.datetimeFormats,
        timeFormat: this.timeFormat,
        sortTypes: this.sortTypes,
        events: this.events,
        messageBusChannels: this.messageBusChannels,
        seniorityAllowancesIndexes: this.seniorityAllowancesIndexes,
        rangeSalaries: this.rangeSalaries,
        approximatedMonth: this.approximatedMonth,
        approximatedYearOld: this.approximatedYearOld,
        numberMonthsInYear: this.numberMonthsInYear,
        maxTimeoutReconnectWithoutLogin: this.maxTimeoutReconnectWithoutLogin,
        localStorageKeys: this.localStorageKeys,
        searchParams: this.searchParams,
        links: this.links,
        bookTypeCode: this.bookTypeCode,
        documentTypeList: this.documentTypeList,
        documentLinkType: this.documentLinkType,
        documentType: this.documentType,
        facetLabel: this.facetLabel,
        documentLinkRelation: this.documentLinkRelation,
        filterAttribute: this.filterAttribute,
        password: this.password,
        documents: this.documents,
        roles: this.roles,
        searchMode: this.searchMode,
        numeralAdverbs: this.numeralAdverbs,
        sourceType: this.sourceType,
        sourceTypeCodePrefix: this.sourceTypeCodePrefix,
        publicationType: this.publicationType,
        translator: this.translator
    };

    openUrls = ['/o/book/detail', '/o/unauthorized'];
}
